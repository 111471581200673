import React from 'react';
import Staffs from '../components/Staffs/StaffList';


const Form = () => {
  return (
    <div>
      
      <Staffs />
       
    </div>
  );
};

export default Form;
