import React from 'react';
import HeroSection from '../components/Home/Home';


const HomePage = () => {
  return (
    <div>
      
      <HeroSection />
       
    </div>
  );
};

export default HomePage;
