import React from 'react';
import Process from '../components/Process/ProcessAnalysis';


const Form = () => {
  return (
    <div>
      
      <Process />
       
    </div>
  );
};

export default Form;
